import React from "react";
import styled, { keyframes } from "styled-components";

export default function Signup() {
  return (
    <Wrapper>
      This is a placeholder for what will probably be an external link for sign
      up / login
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 120px 14vw 140px 14vw;
  min-height: 100vh;
`;
